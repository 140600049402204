export default [
  {
    title: 'Home',
    route: 'cpat-list',
    icon: 'fa-sharp fa-solid fa-house-chimney',
    Permission: '',
  },

  {
    title: 'Reports',
    route: 'settings-growthProfile',
    icon: 'fa-solid fa-file-export',
    Permission: 'see_report_menu',
  },

  {
    title: 'Statistical Analysis',
    route: 'settings-growthStatistic',
    icon: 'fa-sharp fa-solid fa-chart-column',
    Permission: 'see_report_menu',
  },

  {
    title: 'Users',
    icon: 'fa-solid fa-users',
    Permission: 'see_user_menu',
    children: [
      {
        title: 'Add',
        route: 'user-add',
        icon: 'UserPlusIcon',
      },
      {
        title: 'List',
        route: 'user-list',
        icon: 'ListIcon',
      },
      {
        title: 'Edit',
        route: 'user-edit',
        icon: 'EditIcon',
        disabled: true,
      },
    ],
  },

  {
    title: 'Resources',
    route: 'resources',
    icon: 'fa-solid fa-folder-open',
    Permission: '',
  },

  {
    title: 'Support',
    route: 'support',
    icon: 'fa-solid fa-circle-question',
    Permission: '',
  },

  {
    title: 'Settings',
    route: 'settings',
    icon: 'fa-solid fa-gear',
    Permission: 'see_settings_menu',
  },

  {
    title: 'Sign Out',
    route: 'settings',
    icon: 'fa-solid fa-right-from-bracket',
    Permission: '',
  },
];
