<template>
  <!-- <div class="clearfix mb-0 bg-primary" style="color:#EDF7FA">
    <span class="float-md-center d-block d-md-inline-block mt-25">
      <p>
        Copyright ©2023 Responsive Classroom. All rights reserved.
      </p>
      <p>
        <b-link class="ml-25" style="color:#EDF7FA" href="www.responsiveclassroom.org" target="_blank">www.responsiveclassroom.org</b-link>
      </p>
    </span>

    <h1 class="float-md-right d-none d-md-block">CPAT</h1>
  </div> -->

  <footer>
    <div class="footer-container">
      <div class="footer-content">
        <div class="footer-copyright-text">
          Copyright ©2023 Responsive Classroom. All rights reserved. <br />
          www.responsiveclassroom.org
        </div>
        <div class="footer-cpat-text">
          CPAT
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
};
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: #0b4666;
  color: #edf7fa;
  text-align: center;
}

@media (max-width: 768px) {
  footer {
    display: none;
  }
}

.footer-content {
  position: relative;
  display: flex;
  margin-top: 0.5em;
}

.footer-copyright-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: 'Myriad Pro Semibold';
  font-size: 1.3em;
}

.footer-cpat-text {
  position: absolute;
  right: 0;
  margin-right: 25px;
  text-decoration: underline;
  font-family: 'Myriad Pro Semibold';
  font-size: 2.7em;
}
</style>
